// eslint-disable-next-line
import React from 'react';
// import { Helmet } from 'react-helmet';

const SEO = ({ pageTitle, type }) => {

    // const meta = {
    //     url: 'https://saidatech.com',
    //     title: 'Concreap',
    //     description: 'Become a a designer and developer in the crypto & fintech space',
    //     language: "en-US",
    //     image: 'https://storage.googleapis.com/checkaam-buckets/saidatech-seo',
    //     author: {
    //         email: 'hello@saidatech.com',
    //         name: 'Concreap Creative School',
    //         image:'https://storage.googleapis.com/checkaam-buckets/saidatech-seo'
    //     },
    //     site: {
    //         siteName: 'Saidatech Consult',
    //         searchUrl: 'https://www.google.com/search?q=Concreap'
    //     }
    // }
    return (
        <>
            
        </>
    )
}

export default SEO
